import './App.scss';
import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';

import Heading from 'components/atoms/Heading';
import Home from 'pages/Home';
import { store } from 'store';

const scriptsData = `
   var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="//www.blenderanalytics.com/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '17']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();
`;

const App: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = scriptsData;
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path="/:slug" element={<Home />} />
          <Route path="/:slug/:key" element={<Home />} />
          <Route
            key="router-notfound"
            path="*"
            element={(
              <div className="notfound">
                <Heading type="h2" modifiers={['700', 'center']}>
                  Đường dẫn không hợp lệ, xin vui lòng thử lại!
                </Heading>
              </div>
            )}
          />
        </Routes>
      </Router>
    </div>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

const AppWrapper: React.FC = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </Provider>
);

export default AppWrapper;
