import React from 'react';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';

interface ProcessTitleProps {
  title: string;
  content?: string;
  btnText?: string;
  handleClick?: () => void;
}

const ProcessTitle: React.FC<ProcessTitleProps> = ({
  title,
  content,
  btnText,
  handleClick,
}) => (
  <div className="m-processTitle">
    <div className="m-processTitle_wrapTitle">
      <div className="m-processTitle_wrapTitle-title">
        <Text modifiers={['14x20', '500', 'smokyBlack']} content={title} />
      </div>
      {content && <Text modifiers={['14x20', '500', 'firebrick']} content={content} />}
    </div>
    <Button onClick={handleClick} modifiers={['transparent', 'inline']}>
      <Text modifiers={['14x20', '500', 'blueCrayola']}>
        {btnText}
      </Text>
    </Button>
  </div>
);

export default ProcessTitle;
