import React from 'react';

import Header, { HeaderProps } from 'components/organisms/Header';
import useWindowSize from 'hooks/useWindowSize';

interface PageLayoutProps {
  children?: React.ReactNode;
  headerProps?: HeaderProps;
}

const PageLayout: React.FC<PageLayoutProps> = ({ children, headerProps }) => {
  const { height } = useWindowSize();

  return (
    <div className="t-pageLayout" style={{ height: `${height}px` }}>
      <div className="t-pageLayout_header">
        <Header {...headerProps} />
      </div>
      <div className="t-pageLayout_body">
        {children}
      </div>
    </div>
  );
};

PageLayout.defaultProps = {
  children: undefined,
};

export default PageLayout;
