import React from 'react';

import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Text from 'components/atoms/Text';
import CustomModal from 'components/organisms/Modal';

interface NotifyModalProps {
  isOpen: boolean;
  title?: string,
  desc?: string,
  submitText?: string,
  backText?: string,
  handleSubmit?: () => void;
  handleBack?: () => void;
  loadingSubmit?: boolean;
  isError?: boolean;
  smallTitle?: boolean;
}

const NotifyModal: React.FC<NotifyModalProps> = ({
  isOpen,
  title,
  desc,
  submitText,
  backText,
  handleSubmit,
  handleBack,
  loadingSubmit,
  isError,
  smallTitle,
}) => (
  <div className="t-notifyModal">
    <CustomModal
      showIconClose
      isOpen={isOpen}
      modifiers={['width-336']}
    >
      {
        title && (
          <Heading modifiers={['center', smallTitle ? '20x30' : '32x42', '700', 'smokyBlack']} content={title} />
        )
      }
      {
        desc && (
          <div className="t-notifyModal_desc">
            <Text modifiers={['14x20', '500', 'center', 'smokyBlack']} content={desc} />
          </div>
        )
      }
      <div className="t-notifyModal_button">
        {
          backText && (
            <div className="t-notifyModal_button-back" onClick={handleBack}>
              <Button modifiers={['secondary', 'lg']}>
                <Text modifiers={['gunmetal', '16x24', '600']} content={backText} />
              </Button>
            </div>
          )
        }
        {
          submitText && (
            <div className="t-notifyModal_button-submit" onClick={handleSubmit}>
              <Button modifiers={[isError ? 'error' : 'primary', 'lg']} loading={loadingSubmit}>
                <Text modifiers={['white', '16x24', '600']} content={submitText} />
              </Button>
            </div>
          )
        }
      </div>
    </CustomModal>
  </div>
);

export default NotifyModal;
