import React from 'react';

import Text from 'components/atoms/Text';

interface ResultItemProps {
  label: string;
  amount: number;
}

const ResultItem: React.FC<ResultItemProps> = ({
  label,
  amount,
}) => (
  <div className="m-resultItem">
    <div className="m-resultItem_label">
      <Text modifiers={['14x20', '500', 'smokyBlack']}>{label}</Text>
    </div>
    <div className="m-resultItem_amount">
      <Text modifiers={['14x20', '500', 'eerieBlack']}>{amount}</Text>
    </div>
  </div>
);

ResultItem.defaultProps = {
};

export default ResultItem;
