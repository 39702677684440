import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import useWindowSize from 'hooks/useWindowSize';
import mapModifiers from 'utils/functions';

interface SignatureProps {
  handleCompleted?: (file: string) => void;
  error?: string;
}

const Signature: React.FC<SignatureProps> = ({ handleCompleted, error }) => {
  const { width } = useWindowSize();
  const refSignature = useRef<SignatureCanvas>(null);
  const [begin, setBegin] = useState(false);

  const handleClear = () => {
    if (refSignature) {
      refSignature.current?.clear();
      setBegin(false);
      if (handleCompleted) {
        handleCompleted('');
      }
    }
  };

  const handleEnd = async () => {
    if (handleCompleted && refSignature) {
      const result = refSignature.current?.toDataURL('image/png');
      if (result) {
        handleCompleted(result);
      }
    }
  };
  return (
    <div className="m-signature">
      <div className={mapModifiers('m-signature_pad', error ? 'error' : '')}>
        {
          !begin && (
            <div className="m-signature_placeholder">
              <Text modifiers={['14x20', 'grayx11', 'center']}>
                Ký xác nhận vào đây
              </Text>
            </div>
          )
        }
        <SignatureCanvas
          ref={refSignature}
          penColor="green"
          canvasProps={{ width: width - 72, height: 240 }}
          onEnd={handleEnd}
          onBegin={() => setBegin(true)}
        />
      </div>
      {error && <span className="a-text-area_errorMessage">{error}</span>}
      <div className="m-signature_button">
        <Button modifiers={['secondary', 'lg']} onClick={handleClear}>
          <Text modifiers={['16x24', '600', 'gunmetal']}>
            Ký lại
          </Text>
        </Button>
      </div>
    </div>
  );
};

Signature.defaultProps = {
};

export default Signature;
