import React from 'react';

import Text from 'components/atoms/Text';

export interface HeaderTypes {
  field: string,
  headerName: string;
  width?: number;
}

export interface DataTableTypes {
  [x: string]: number | string
}

interface TableProps {
  dataTable?: DataTableTypes[];
  headerTable?: HeaderTypes[];
}

const Table: React.FC<TableProps> = ({ headerTable, dataTable }) => (
  <table className="o-table">
    <thead>
      <tr>
        {
          headerTable && headerTable.map((item, index) => (
            <th key={`table-head-${index.toString()}`} style={{ width: item.width }}>
              <Text modifiers={['12x14', '500', 'white']}>
                {item.headerName}
              </Text>
            </th>
          ))
        }
      </tr>
    </thead>
    <tbody>
      {
        headerTable && dataTable && dataTable.map((ele, index) => (
          <tr key={`table-${index.toString()}`}>
            {
              Object.keys(ele).map((item, idx) => (
                <td key={`tr-${idx.toString()}`}>
                  <Text modifiers={['12x14', '500', 'smokyBlack']}>
                    {ele[headerTable[idx]?.field]}
                  </Text>
                </td>
              ))
            }
          </tr>
        ))
      }
      {dataTable?.length === 0 && (
        <tr>
          <td className="o-table_noData" colSpan={headerTable?.length}>
            Không có dữ liệu trong bảng
          </td>
        </tr>
      )}
    </tbody>
  </table>
);

export default Table;
