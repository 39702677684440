import React from 'react';

import Icon from 'components/atoms/Icon';
import Text from 'components/atoms/Text';

export interface HeaderProps {
  isBack?: boolean;
  title?: string;
  handleBackToOutlet?: () => void;
  handleBack?: () => void;
}

const Header: React.FC<HeaderProps> = ({
  isBack, title,

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleBackToOutlet,
  handleBack,
}) => (
  <div className="o-header_primary">
    {
      isBack && (
        <div
          className="o-header_primary-icon"
          onClick={() => {
            if (handleBack) {
              handleBack();
            }
          }}
        >
          <Icon iconName="backBlack" size="28" />
        </div>
      )
    }
    <Text modifiers={['16x24', '600', 'gunmetal']}>
      {title}
    </Text>
  </div>
);

export default Header;
