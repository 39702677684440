import {
  EReportDataTypes,
  EReportParams,
  EReportPostParams
} from './types';

import axiosInstance from 'services/common/instance';

export const getEreportServices = async (params: EReportParams)
  : Promise<EReportDataTypes> => {
  const res = await axiosInstance.get('e-report', { params });
  return res.data.data;
};

export const postEreportServices = async (params: EReportPostParams)
  : Promise<void> => {
  const bodyForm = new FormData();
  bodyForm.append('token', params.token);
  bodyForm.append('key', params.key);
  bodyForm.append('status', String(params.status));
  if (params.note) {
    bodyForm.append('note', params.note);
  }
  if (params?.file) {
    bodyForm.append('file', params?.file);
  }

  const res = await axiosInstance.post('e-report', bodyForm);
  return res.data.data;
};
